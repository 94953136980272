import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"
import {
  Row,
  Col,
  Button,
  Accordion,
  Card,
  InputGroup,
  FormControl,
  Form,
} from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const MembersPageTemplate = ({ title, subTitle, sec1, sec2, sec3 }) => {
  return (
    <Layout page="members">
      <SEO title="Members" />
      <Row className="mx-0" style={{ marginTop: "-73px" }}>
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center members-landing-image">
            <div className="overlay"></div>
            <div className="sectionTitleWrap">
              <h1 className="sectionTitle">{title}</h1>
              <h2 className="sectionSubTitle">{subTitle}</h2>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex flex-column align-items-center members-section2">
        <Col xs={10} lg={8}>
          <div className="wrapper">
            <ReactMarkdown source={sec1.content} linkTarget="_blank" />
          </div>
        </Col>
      </Row>

      <Row className="members-section-columns d-flex flex-row justify-content-center">
        <Col xs={5} xl={4} className="col-wrapper">
          <ReactMarkdown source={sec1.columnLeft} linkTarget="_blank" />
        </Col>
        <Col
          xs={{ span: 5, offset: 1 }}
          xl={{ span: 4, offset: 1 }}
          className="col-wrapper"
        >
          <ReactMarkdown source={sec1.columnRight} linkTarget="_blank" />
        </Col>
      </Row>
      <Row className="members-section-this-year d-flex flex-column justify-content-center align-items-center pt-5 pb-3">
        <Col xs={12} sm={10} xl={8}>
          <ReactMarkdown source={sec2} linkTarget="_blank" />
        </Col>
      </Row>
      <Row className="members-section-beyond">
        <div className="overlay-top"></div>
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center bg-image">
            <div className="overlay"></div>
            <div className="sectionTitleWrapper">
              <h1 className="sectionTitle">{sec3.title}</h1>
            </div>
          </div>
        </Col>
        <div className="overlay-bottom"></div>
      </Row>
      <Row className="py-5 d-flex flex-column mx-0 members-section-lower align-items-center">
        <Col xs={12} sm={10}>
          <p style={{ color: "#138a8eff" }}>
            <ReactMarkdown source={sec3.text1} linkTarget="_blank" />
          </p>
        </Col>
        <Col xs={12} sm={10}>
          <h1>
            <span>Shu</span>HaRi
          </h1>
          <p>
            <ReactMarkdown source={sec3.text2} linkTarget="_blank" />
          </p>
          <h1>
            Shu<span>Ha</span>Ri
          </h1>
          <p>
            <ReactMarkdown source={sec3.text3} linkTarget="_blank" />
          </p>
          <h1>
            ShuHa<span>Ri</span>
          </h1>
          <p>
            <ReactMarkdown source={sec3.text4} linkTarget="_blank" />
          </p>
        </Col>
      </Row>

      <Row className="members-section-news d-flex flex-row justify-content-center align-items-center">
        <div className="overlay"></div>
        <Col xs={8}>
          <div className="w-100 d-flex flex-column justify-content-center align-items-center">
            <div className="sectionTitleWrapper">
              <h1 className="sectionTitle">Newsletter</h1>
              <p>
                Stay up-to-date about Lightschools’ events & receive inspiration
                with our newsletter, which appears 13 times a year.
              </p>
            </div>
          </div>
          <Form
            className="mt-5"
            action="https://lightschools.us18.list-manage.com/subscribe/post?u=36dbb1ad924c4a22ea4e7ff3b&id=deb0c25bcf"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="validate"
            target="_blank"
            novalidate
          >
            <Row>
              <Col xs={6}>
                <Form.Control size="lg" placeholder="First name" name="FNAME" />
              </Col>
              <Col xs={6}>
                <Form.Control size="lg" placeholder="Last name" name="LNAME" />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <InputGroup className="mt-3">
                  <FormControl
                    size="lg"
                    placeholder="enter your email address"
                    aria-label="enter your email address"
                    aria-describedby="email address"
                    name="EMAIL"
                  />
                  <InputGroup.Append>
                    <Button type="submit">Sign Up</Button>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={8} className="align-self-center"></Col>
      </Row>
    </Layout>
  )
}

const MembersPage = props => {
  const { frontmatter } = props.data.MembersPageTemplate
  console.log(frontmatter)
  return (
    <MembersPageTemplate
      title={frontmatter.title}
      subTitle={frontmatter.subTitle}
      sec1={frontmatter.sec1}
      sec2={frontmatter.sec2}
      sec3={frontmatter.sec3}
    />
  )
}

export default MembersPage

export const pageQuery = graphql`
  query($id: String!) {
    MembersPageTemplate: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subTitle
        sec1 {
          content
          columnLeft
          columnRight
        }
        sec2
        sec3 {
          title
          text1
          text2
          text3
          text4
        }
      }
    }
  }
`
